import React from 'react';
import styled from 'styled-components';

import initTranslations from '../../../../../../lib/initTranslations';
import DefaultButton from '../../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxSm, mediaBreakpointPxXl } from '../../../../../styled/Breakpoint';
import { deprecated, fontLg2, fontSm5 } from '../../../../../styled/TypeSystem';
import InverseSecondaryButton from '../../shared/InverseSecondaryButton';

const StyledH1 = styled.h1<{ purpleTheme: boolean }>`
  color: ${({ purpleTheme, theme: { vars } }) =>
    purpleTheme ? vars.textSurface : vars.textDefault};
  white-space: nowrap;
  margin-bottom: ${({ theme }) => theme.constants.spacerMd2};
  max-width: 31rem;
  margin-top: -6rem;
  font-weight: ${({ theme }) => theme.constants.fontBold};

  @media (min-width: ${mediaBreakpointPxSm}) {
    margin-top: ${({ theme }) => theme.constants.spacerLg2};
    ${fontLg2};
  }
  ${deprecated.fontMd4};
`;

const BodyText = styled.p<{ purpleTheme: boolean }>`
  color: ${({ purpleTheme, theme: { vars } }) =>
    purpleTheme ? vars.textSurface : vars.textDefault};
  max-width: 31rem;

  @media (min-width: ${mediaBreakpointPxSm}) {
    ${deprecated.fontMd2};
  }
  ${fontSm5};
`;

const ContentWrapper = styled.div<{ purpleTheme: boolean }>`
  margin: 8rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 3.5rem;
  background-color: ${({ purpleTheme, theme: { vars } }) =>
    purpleTheme ? vars.trainualBrandPurpleMedium : vars.foundationBase1};

  @media (min-width: ${mediaBreakpointPxXl}) {
    text-align: left;
    align-items: flex-start;
    margin: 8rem auto 8rem 0;
  }
`;

const Textwrapper = styled.div`
  max-width: 21rem;

  @media (min-width: ${mediaBreakpointPxSm}) {
    max-width: 100%;
  }
`;

const StyledA = styled.a<{ purpleTheme: boolean }>`
  color: ${({ purpleTheme, theme: { vars } }) =>
    purpleTheme ? vars.textSurface : vars.textDefault};
  text-decoration: underline;

  :hover {
    color: ${({ purpleTheme, theme: { vars } }) =>
      purpleTheme ? vars.textSurface : vars.textDefault};
    cursor: pointer;
  }
`;

const t = initTranslations('public_share');

export interface Props {
  purpleTheme: boolean;
}

function ErrorContent({ purpleTheme }: Props) {
  const ActionButton = purpleTheme ? InverseSecondaryButton : DefaultButton;

  return (
    <ContentWrapper purpleTheme={purpleTheme}>
      <Textwrapper>
        <StyledH1 purpleTheme={purpleTheme}>{t('404_page.title')}</StyledH1>
        <BodyText purpleTheme={purpleTheme}>
          {t('404_page.body_text_start')}
          <StyledA href='https://help.trainual.com/en/' purpleTheme={purpleTheme}>
            {' '}
            {t('404_page.body_text_link')}{' '}
          </StyledA>
          {t('404_page.body_text_end')}
        </BodyText>
      </Textwrapper>
      <ActionButton
        buttonType='primary'
        id='explore-button'
        onClick={() => (window.location.href = 'https://trainual.com')}
        text={t('404_page.explore_trainual', { productTerm: 'Trainual' })}
      />
    </ContentWrapper>
  );
}
export default ErrorContent;
