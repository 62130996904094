import { EnhancedStore, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { publicConfigApi } from '../services/publicConfigService';
import { publicShareApi } from '../services/publicShareService';

const reducer = {
  [publicShareApi.reducerPath]: publicShareApi.reducer,
  [publicConfigApi.reducerPath]: publicConfigApi.reducer,
};

let store: EnhancedStore;
const initStore = () => {
  if (store) return store;

  store = configureStore({
    reducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(publicShareApi.middleware);
    },
  });

  setupListeners(store.dispatch);

  return store;
};

export default initStore;
