import confetti from 'canvas-confetti';
import { sample } from 'lodash';
import React, { MouseEvent, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled, { useTheme } from 'styled-components';

import TrainualLogo from '../../../../../../../assets/images/trainual_logo_white.svg';
import TrainualPurpleLogo from '../../../../../../../assets/images/trainual-purple-logo.png';
import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import { mediaBreakpointPxXl } from '../../../../styled/Breakpoint';
import Banner from '../shared/Banner';
import ErrorContent from './components/ErrorContent';
import Footer from './components/Footer';
import OopsBlock from './components/OopsBlock';

const Backdrop = styled.div<{ purpleTheme: boolean }>`
  background-color: ${({ purpleTheme, theme: { vars } }) =>
    purpleTheme ? vars.trainualBrandPurpleMedium : vars.foundationBase1};
  height: 100vh;
`;

const ContentContainer = styled.div<{ purpleTheme: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  margin-left: auto;
  margin-right: auto;
  max-width: 70rem;
  min-height: 65vh;
  background-color: ${({ purpleTheme, theme: { vars } }) =>
    purpleTheme ? vars.trainualBrandPurpleMedium : vars.foundationBase1};

  @media (min-width: ${mediaBreakpointPxXl}) {
    flex-direction: row;
  }
`;

const ConfettiContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
`;

const t = initTranslations('public_share');

const ErrorPage = () => {
  const [cookies] = useCookies(['trainual_current_user_id']);
  const isSignedInUser = !!cookies.trainual_current_user_id;
  const btnText = isSignedInUser ? t('banner.jump_back_in') : t('banner.try_for_free');
  const [purpleTheme] = useState(Boolean(sample([true, false])));
  const { isMobile } = useWindowResize();
  const confettiContainerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const onClick = () => {
    window.location.href = window.location.origin.replace('share', 'app');
  };

  const confettiClick = (event: MouseEvent<HTMLDivElement>) => {
    const id = (event.target as Element).id;
    if (id === 'error-cta' || id === 'public-share-banner-button' || isMobile) return;
    const container = document.getElementById('confetti-container');

    confetti({
      particleCount: 100,
      startVelocity: 30,
      spread: 360,
      origin: {
        y: event.clientY / (container?.clientHeight || 1),
        x: event.clientX / (container?.clientWidth || 1),
      },
    });
  };

  const logo = purpleTheme ? TrainualPurpleLogo : TrainualLogo;

  return (
    <ConfettiContainer
      id='confetti-container'
      onClick={(e) => confettiClick(e)}
      ref={confettiContainerRef}
    >
      <Banner
        bannerBtnText={btnText}
        isAccented={!purpleTheme}
        limitedWidth
        logoBackgroundColor={
          purpleTheme ? theme.vars.foundationSurface1 : theme.vars.trainualBrandPurpleMedium
        }
        logoUrl={logo}
        onClick={onClick}
      />
      <Backdrop purpleTheme={purpleTheme}>
        <ContentContainer purpleTheme={purpleTheme}>
          <ErrorContent purpleTheme={purpleTheme} />
          <OopsBlock purpleTheme={purpleTheme} />
        </ContentContainer>
      </Backdrop>
      <Footer parentRef={confettiContainerRef} purpleTheme={purpleTheme || false} />
    </ConfettiContainer>
  );
};

export default ErrorPage;
