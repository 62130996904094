import React, { ReactElement, ReactNode } from 'react';

import usePublicRoutes, { Routes } from '../hooks/usePublicRoutes';

type Props = {
  children: ReactNode;
};

const PublicRoutesContext = React.createContext<Routes>({} as Routes);

const PublicRoutesProvider = ({ children }: Props): ReactElement => {
  const routes = usePublicRoutes();
  return <PublicRoutesContext.Provider value={routes}>{children}</PublicRoutesContext.Provider>;
};

export { PublicRoutesProvider };
