import React, { ReactElement, ReactNode, Suspense, lazy } from 'react';
import ReactOnRails from 'react-on-rails';
import { Provider } from 'react-redux';

import { ContentStyleDesignationProvider } from '../contexts/ContentStyleDesignationContext';
import { PublicConfigsProvider } from '../contexts/PublicConfigsContext';
import { PublicRoutesProvider } from '../contexts/PublicRoutesContext';
import { ComponentRegistry } from '../types/ComponentRegistry';
import { ContentStyles } from '../types/ContentStyle';
import StyleConfiguration from '../types/StyleConfiguration';
import ErrorPage from './application/curriculums/public_share/error_page';
import CssReset from './CssReset';
import { FlashNotificationProvider } from './FlashNotificationContext';
import LoadInitialPublicState from './LoadInitialPublicState';
import { ReactInstanceProvider } from './ReactInstanceContext';
import StyleScene from './StyleScene';

const PublicShareApp = lazy(() => import('./application/curriculums/public_share/PublicShareApp'));

type Props = StyleConfiguration & {
  componentName: string;
  publicCurriculumId: string;
  children?: ReactNode;
  contentStyles: ContentStyles;
};

// Only need here if referencing in `=react_component(...)` invocation
const registry: ComponentRegistry = {
  PublicShareApp: (props: Props) => <PublicShareApp {...props} />,
  ErrorPage: (props: JSX.IntrinsicAttributes) => <ErrorPage {...props} />,
};

// Needed bc the contexts are not available when doing this more directly for some unknown reason
const TheComponent = ({ componentName, ...propsToPass }: Props): ReactElement => {
  if (!Object.prototype.hasOwnProperty.call(registry, componentName)) {
    throw `Component '${componentName}' not registered in PublicRoot.tsx`;
  }
  const component = registry[componentName];
  return component(propsToPass);
};

const PublicRoot = (props: Props): ReactElement => {
  const { accentColor, accentPalette } = props;

  const store = ReactOnRails.getStore('publicShareStore', true);

  return (
    <ReactInstanceProvider>
      <Provider store={store}>
        <ContentStyleDesignationProvider designation='trainual'>
          <PublicConfigsProvider>
            <LoadInitialPublicState>
              <StyleScene accentColor={accentColor} accentPalette={accentPalette} mode='light'>
                <PublicRoutesProvider>
                  <CssReset />
                  <FlashNotificationProvider>
                    <Suspense fallback={<div />}>
                      <TheComponent {...props} />
                    </Suspense>
                  </FlashNotificationProvider>
                </PublicRoutesProvider>
              </StyleScene>
            </LoadInitialPublicState>
          </PublicConfigsProvider>
        </ContentStyleDesignationProvider>
      </Provider>
    </ReactInstanceProvider>
  );
};

export const PublicShareRoot = (props: Props) => <PublicRoot {...props} />;
