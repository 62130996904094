import React from 'react';
import styled from 'styled-components';

import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import { mediaBreakpointPxLg } from '../../../../styled/Breakpoint';
import { fontSm5 } from '../../../../styled/TypeSystem';
import InverseSecondaryButton from './InverseSecondaryButton';

const BannerContainer = styled.div<{ isAccented: boolean }>`
  display: flex;
  position: sticky;
  width: 100%;
  justify-content: center;
  padding: ${({ theme }) => theme.constants.spacerSm3} ${({ theme }) => theme.constants.spacerMd2};
  top: 0;
  left: 0;
  max-height: 4.5rem;
  background-color: ${({ theme, isAccented }) =>
    isAccented ? theme.vars.accentPrimaryDefault : theme.vars.foundationSurface1};
  text-align: center;
  z-index: 3;
`;

const LogoWrapper = styled.a<{ limitedWidth: boolean; logoBackgroundColor: string | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  aspect-ratio: 1;
  margin-left: -${({ theme }) => theme.constants.spacerSm1};
  padding: ${({ theme }) => theme.constants.spacerSm2};

  @media (min-width: ${mediaBreakpointPxLg}) {
    aspect-ratio: auto;
    height: 2.5rem;
    max-width: 6.25rem;
    margin-right: ${({ theme }) => theme.constants.spacerMd3};
    margin-left: ${({ theme, limitedWidth }) =>
      limitedWidth ? '0' : `-${theme.constants.spacerSm1}`};
  }

  ${({ logoBackgroundColor }) =>
    logoBackgroundColor &&
    `
    background-color: ${logoBackgroundColor}
  `}
`;

const CompanyLogo = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const MessageSpan = styled.span`
  flex-grow: 4;
  font-weight: ${({ theme }) => theme.constants.fontRegular};
  color: ${({ theme }) => theme.vars.textSurface};
  display: flex;
  justify-content: center;
  margin-right: ${({ theme }) => theme.constants.spacerSm3};
  ${fontSm5};
`;

const StyledDiv = styled.div<{ limitedWidth: boolean }>`
  max-width: ${({ limitedWidth }) => (limitedWidth ? '70rem' : 'none')};
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
`;

export interface Props {
  bannerMsg?: string;
  bannerBtnText: string;
  onClick: () => void;
  isAccented?: boolean;
  limitedWidth?: boolean;
  logoUrl: string;
  logoBackgroundColor: string | null;
}

const Banner = ({
  bannerMsg,
  bannerBtnText,
  onClick,
  isAccented = true,
  limitedWidth = false,
  logoUrl,
  logoBackgroundColor,
}: Props) => {
  const ActionButton = isAccented ? InverseSecondaryButton : DefaultButton;

  return (
    <BannerContainer className='public-share-banner' isAccented={isAccented}>
      <StyledDiv limitedWidth={limitedWidth}>
        <LogoWrapper
          href='https://trainual.com'
          limitedWidth={limitedWidth}
          logoBackgroundColor={logoBackgroundColor}
          target='_blank'
        >
          <CompanyLogo src={logoUrl} />
        </LogoWrapper>
        <MessageSpan>{bannerMsg}</MessageSpan>
        <ActionButton id='public-share-banner-button' onClick={onClick} text={bannerBtnText} />
      </StyledDiv>
    </BannerContainer>
  );
};

export default Banner;
