import React, { MutableRefObject, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import Eyes from '../../../../../../../../assets/images/eyes.svg';
import LoopDeLoop from '../../../../../../../../assets/images/loop_de_loop.svg';
import initTranslations from '../../../../../../lib/initTranslations';
import Badge from '../../../../../design_system/display/badge';
import { mediaBreakpointPxLg } from '../../../../../styled/Breakpoint';
import { fontMd1 } from '../../../../../styled/TypeSystem';

const FooterContainer = styled.div<{ purpleTheme: boolean }>`
  display: none;

  @media (min-width: ${mediaBreakpointPxLg}) {
    display: flex;
    background: ${({ purpleTheme, theme: { vars } }) =>
      purpleTheme ? vars.foundationSurface1 : vars.trainualBrandPurpleMedium};
    padding: 0;
    position: relative;
    width: 100vw;
    bottom: 0;
  }
`;

const CenterDiv = styled.div<{ purpleTheme: boolean }>`
  text-align: center;
  margin: auto;
  color: ${({ purpleTheme, theme: { vars } }) =>
    purpleTheme ? vars.textDefault : vars.textSurface};
  font-weight: ${({ theme }) => theme.constants.fontHeavy};
  ${fontMd1};
`;

const EyesImg = styled.img`
  padding-top: ${({ theme }) => theme.constants.spacerLg2};
  padding-bottom: ${({ theme }) => theme.constants.spacerLg3};
`;

const LoopImg = styled.img`
  padding-top: ${({ theme }) => theme.constants.spacerLg3};
  padding-bottom: ${({ theme }) => theme.constants.spacerLg2};
`;

const StepsContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.constants.spacerSm3};
`;

const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const StepDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: ${({ theme }) => theme.constants.spacerMd2};
`;

const StyledP = styled.p<{ purpleTheme: boolean }>`
  color: ${({ purpleTheme, theme: { vars } }) =>
    purpleTheme ? vars.textDefault : vars.textSurface};
  font-weight: ${({ theme }) => theme.constants.fontMedium};
`;

const eyeStyling = css`
  background-color: white;
  border-radius: 25px;
  height: 2.15rem;
  width: 2.15rem;
  padding: 0;
  z-index: 1;
`;

const LeftEye = styled.div`
  ${eyeStyling};
  margin: ${({ theme: { constants } }) =>
    `${constants.spacerSm3} -6.75rem 0 ${constants.spacerMd2}`};
`;

const RightEye = styled.div`
  ${eyeStyling};
`;

const Pupil = styled.div`
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
  background-color: black;
  z-index: 2;
  height: 1.15rem;
  width: 1rem;
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} 0 0 ${constants.spacerMd2}`};
`;

const Face = styled.div`
  display: flex;
  position: absolute;
  top: 41%;
  right: 49%;
  gap: 1.15rem;
`;

const EyesWrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
`;

const handleEyeMovement = (e: MouseEvent, eyes: HTMLDivElement[]) => {
  const mouseX = e.pageX;
  const mouseY = e.pageY;

  eyes.forEach((sqr: HTMLDivElement) => {
    const diffX = mouseX - 250;
    const diffY = mouseY - 250;

    const radians = Math.atan2(diffY, diffX);

    const angle = (radians * 180) / Math.PI;

    sqr.style.transform = `rotate(${angle}deg)`;
  });
};

const t = initTranslations('public_share.footer');

export interface Props {
  purpleTheme: boolean;
  parentRef: MutableRefObject<HTMLDivElement | null>;
}

function Footer({ purpleTheme, parentRef }: Props) {
  const eyesRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const eyes = eyesRef.current;
    const currentRef = parentRef?.current;

    currentRef?.addEventListener('mousemove', (e) => handleEyeMovement(e, eyes));

    return () => {
      currentRef?.removeEventListener('mousemove', (e) => handleEyeMovement(e, eyes));
    };
  }, [parentRef]);

  return (
    <FooterContainer purpleTheme={purpleTheme}>
      <ImageDiv>
        <EyesWrapper>
          <Face>
            <LeftEye
              ref={(element) => {
                if (element) eyesRef.current[0] = element;
              }}
            >
              <Pupil />
            </LeftEye>
            <RightEye
              ref={(element) => {
                if (element) eyesRef.current[1] = element;
              }}
            >
              <Pupil />
            </RightEye>
          </Face>
          <EyesImg src={Eyes} />
        </EyesWrapper>
      </ImageDiv>
      <CenterDiv purpleTheme={purpleTheme}>
        <strong>{t('make_it_up')}</strong>
        <StepsContainer>
          <StepDiv>
            <Badge size='md' text={t('step_1')} type='general' />
            <StyledP purpleTheme={purpleTheme}>{t('click')}</StyledP>
          </StepDiv>
          <StepDiv>
            <Badge size='md' text={t('step_2')} type='general' />
            <StyledP purpleTheme={purpleTheme}>{t('watch')}</StyledP>
          </StepDiv>
        </StepsContainer>
      </CenterDiv>
      <ImageDiv>
        <LoopImg src={LoopDeLoop} />
      </ImageDiv>
    </FooterContainer>
  );
}

export default Footer;
