import styled from 'styled-components';

import defaultButton from '../../../../design_system/buttons/DefaultButton/DefaultButton';
import { mediaBreakpointPxMd } from '../../../../styled/Breakpoint';
import { fontSm4, fontSm5 } from '../../../../styled/TypeSystem';

const InverseSecondaryButton = styled(defaultButton)`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.vars.textSurface};
  color: ${({ theme }) => theme.vars.accentPrimaryDefault};
  border: ${({ theme }) => theme.constants.borderWidthLg} solid
    ${({ theme }) => theme.vars.accentPrimaryDefault};
  padding: ${({ theme }) => theme.constants.spacerSm3} ${({ theme }) => theme.constants.spacerMd2};
  font-weight: ${({ theme }) => theme.constants.fontMedium};
  white-space: nowrap;

  @media (max-width: ${mediaBreakpointPxMd}) {
    padding: ${({ theme }) => theme.constants.spacerSm2} ${({ theme }) => theme.constants.spacerSm2};
    height: auto;
    ${fontSm4};
  }

  :hover {
    background-color: ${({ theme }) => theme.vars.accentPrimaryDefault};
    color: ${({ theme }) => theme.vars.textSurface};
    border: ${({ theme }) => theme.constants.borderWidthLg} solid
      ${({ theme }) => theme.vars.textSurface};
  }

  :focus {
    outline: none;
    border: ${({ theme }) => theme.constants.borderWidthLg} solid
      ${({ theme }) => theme.vars.foundationSurface1};
    background-color: ${({ theme }) => theme.vars.accentPrimaryDefault};
    color: ${({ theme }) => theme.vars.textSurface};
  }
  ${fontSm5};
`;

export default InverseSecondaryButton;
