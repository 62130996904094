import { useCallback, useMemo } from 'react';

import { Route } from './useAccountRoutes';

type PublicCurriculumRouteArgs = { publicCurriculumId: string };
type PublicCurriculumRoute = Route<PublicCurriculumRouteArgs>;

type PublicStepRouteArgs = { publicCurriculumId?: string; topicId?: string; stepId?: string };
type PublicStepRoute = Route<PublicStepRouteArgs>;

export interface Routes {
  ajax: {
    publicCurriculums: PublicCurriculumRoute;
    publicStep: PublicStepRoute;
  };
}

const useAccountRoutes = () => {
  const ajaxRoot = useCallback((resource: string, id?: number | string) => {
    const pathParts = [window.location.origin, 'ajax', resource];
    if (id) {
      pathParts.push(`${id}`);
    }
    return pathParts.join('/');
  }, []);

  const routes = useMemo(
    (): Routes =>
      ({
        ajax: {
          publicCurriculums: ({ publicCurriculumId }) =>
            `${ajaxRoot('public_curriculums', publicCurriculumId)}`,
          publicStep: ({ publicCurriculumId, topicId, stepId }) =>
            `${ajaxRoot(
              'public_curriculums',
              publicCurriculumId
            )}/courses/${topicId}/steps/${stepId}`,
        },
      } as Routes),
    [ajaxRoot]
  );

  return routes;
};

export default useAccountRoutes;
