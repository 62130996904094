import React from 'react';
import styled from 'styled-components';

import { TrianglePurple, TriangleYellow } from '../../../../../../lib/gcsImages';
import initTranslations from '../../../../../../lib/initTranslations';
import { mediaBreakpointPxSm, mediaBreakpointPxXl } from '../../../../../styled/Breakpoint';

const OopsContainer = styled.div`
  position: relative;
  margin-top: ${({ theme: { constants } }) => constants.spacerLg3};
  height: 12rem;
`;

const Oops = styled.h1<{ purpleTheme: boolean }>`
  position: relative;
  font-size: 6.25rem;
  text-align: center;
  z-index: 2;
  margin-top: 6.375rem;
  font-weight: ${({ theme }) => theme.constants.fontBold};
  color: ${({ purpleTheme, theme: { vars } }) =>
    purpleTheme ? vars.textSurface : vars.textDefault};
  margin-left: ${({ theme }) => theme.constants.spacerSm3};

  @media (min-width: ${mediaBreakpointPxXl}) {
    text-align: right;
    margin-right: 5rem;
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    font-size: 9.375rem;
    margin-top: 9.375rem;
  }
`;
const StyledImg = styled.img<{ purpleTheme: boolean }>`
  position: absolute;
  top: -${({ theme }) => theme.constants.spacerMd3};
  right: ${({ theme }) => theme.constants.spacerMd3};
  max-width: 12rem;
  transform: ${({ purpleTheme }) => (purpleTheme ? 'rotate(180)' : 'rotate(90deg)')};

  @media (max-width: ${mediaBreakpointPxXl}) {
    margin-right: ${({ theme }) => theme.constants.spacerLg1};
  }

  @media (max-width: ${mediaBreakpointPxSm}) {
    margin-right: 0;
  }

  @media (min-width: ${mediaBreakpointPxSm}) {
    top: 0;
    right: 0;
    max-width: 18.5rem;
  }
`;

const t = initTranslations('public_share.404_page');

export interface Props {
  purpleTheme: boolean;
}

function OopsBlock({ purpleTheme }: Props) {
  return (
    <OopsContainer>
      <StyledImg purpleTheme={purpleTheme} src={purpleTheme ? TrianglePurple : TriangleYellow} />
      <Oops purpleTheme={purpleTheme}>{t('oops')}</Oops>
    </OopsContainer>
  );
}

export default OopsBlock;
