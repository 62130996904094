// Vite - Virtual Files
import '@generate_theme_files';

import ReactOnRails from 'react-on-rails';

import { DataDogRum } from '../react/components/DataDogRum';
import { PublicShareRoot as PublicRoot } from '../react/components/PublicRoot';
import { initializeFonts } from '../react/lib/font_libraries';
import initStore from '../react/redux/stores/publicShareStore';

initializeFonts();

const publicShareStore = () => {
  return initStore();
};

ReactOnRails.registerStore({
  publicShareStore,
});

ReactOnRails.register({
  DataDogRum,
  PublicRoot,
});
